import { Heading, Spacing } from '@betaeducacao/react-components'
import Cart from './cart'

export default function Layout({ children }) {
  return (
    <div className="grid md:grid-cols-6 gap-4">
      <Spacing className="md:col-span-4">{children}</Spacing>

      <Spacing className="md:col-span-2">
        <Heading size="2xl">Seu carinho</Heading>
        <Cart />
      </Spacing>
    </div>
  )
}
