import {
  calcInterest,
  kindIsDiscount,
  kindIsInterest,
  kindIsItem,
  kindIsItemNotDiscount,
  sumItems,
} from '@betaeducacao/react-components'
import { useState } from 'react'
import CheckoutContext from './context'

export default function CheckoutProvider({ children, items: _items, allowManualPayment, dueDate }) {
  const [items, setItems] = useState(_items)
  const [installments, setInstallments] = useState(1)

  const updateItems = (items, installments) => {
    if (installments === 1) {
      return setItems(items.filter(kindIsItem))
    }

    const total = sumItems(items.filter(kindIsItemNotDiscount))
    const interest = calcInterest(total, installments) - total

    setItems([
      ...items.filter(kindIsItem),
      {
        id: 'interest',
        title: 'Juros',
        unit_price: parseInt(interest),
        quantity: 1,
      },
    ])
  }

  const setItemQuantity = (id, quantity) => {
    const idx = items.findIndex((item) => item['id'] === id)
    const newItems = [...items]

    // Updates the quantity's item
    if (quantity > 0) {
      newItems[idx].quantity = quantity
    }
    // Removes the item
    if (quantity === 0) {
      newItems.splice(idx, 1)
    }

    updateItems(newItems, installments)
  }

  const setInterest = (installments) => {
    setInstallments(installments)
    updateItems(items, installments)
  }

  const resetInterest = () => {
    setInstallments(1)
    updateItems(items, 1)
  }

  return (
    <CheckoutContext.Provider
      value={{
        total: sumItems(items),
        subtotal: sumItems(items.filter(kindIsItemNotDiscount)),
        discount: sumItems(items.filter(kindIsDiscount)),
        interest: sumItems(items.filter(kindIsInterest)),
        forcesCNPJ: items.filter((x) => x.quantity > 1).length >= 1,
        items,
        allowManualPayment,
        dueDate,
        setItemQuantity,
        setInterest,
        resetInterest,
      }}
    >
      <fieldset disabled={items.length === 0}>{children}</fieldset>
    </CheckoutContext.Provider>
  )
}
