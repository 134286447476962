import { Alert, Challenge, Skeleton, Spacing, useAxios } from '@betaeducacao/react-components'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Address, Buyer, CheckoutProvider, Id, Payment } from '../components/checkout'
import Faq from '../components/faq'

export default function Checkout() {
  const axios = useAxios()
  const { id } = useParams()
  const {
    data = { items: [] },
    isError,
    isLoading,
  } = useQuery(['carts', id], async () => {
    const { data } = await axios.get(`/carts/${id}`)
    return data
  })

  return (
    <>
      {isError && (
        <Alert icon={<ExclamationCircleIcon className="h-5 w-5" />} className="md:p-4" colorScheme="warning">
          Seu carrinho expirou
        </Alert>
      )}

      {isLoading ? (
        <Spacing>
          <Skeleton className="rounded-md" rows={['h-[36px] w-7/12', 'h-[56px] w-full', 'h-[191px] w-full']} />
        </Spacing>
      ) : (
        <CheckoutProvider items={data.items} allowManualPayment={data?.manual_payment} dueDate={data?.due_date}>
          <Challenge>
            <Id name="id" disabled={isError} />
            <Buyer name="buyer" />
            <Address name="address" />
            <Payment name="payment" />
          </Challenge>
        </CheckoutProvider>
      )}

      <Faq />
    </>
  )
}
