export default function Copyright({ appName }) {
  const year = new Date().getFullYear()

  return (
    <div className="text-center text-gray-500 text-sm font-light">
      &copy; {year} {appName} &ndash; Todos os direitos reservados
      <div className="mt-2">
        Rod. Admar Gonzaga, 440 - Ed. America Officenter, SL 102
        <br />
        Itacorubi - CEP: 88034-000 - Florianópolis/SC
        <br />
        CNPJ: 15.608.435/0001-90
      </div>
    </div>
  )
}
