import { Card, HStack, Skeleton as XSkeleton } from '@betaeducacao/react-components'
import React from 'react'

const ThanksComponent = React.lazy(() => import('../components/thanks'))

function Skeleton() {
  return (
    <>
      <Card>
        <Card.Body className="space-y-6 md:p-8">
          <HStack className="justify-between">
            <XSkeleton rows={['w-5/12 h-9', 'w-2/12 h-7']} className="rounded-md" />
          </HStack>

          <div className="space-y-1">
            <XSkeleton rows={['w-8/12 h-5', 'w-7/12 h-5', 'w-6/12 h-5']} className="rounded-md" />
          </div>

          <div className="space-y-1">
            <XSkeleton rows={['w-7/12 h-5', 'w-8/12 h-5', 'w-6/12 h-5', 'w-2/12 h-5']} className="rounded-md" />
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default function Thanks() {
  return (
    <React.Suspense fallback={<Skeleton />}>
      <ThanksComponent />
    </React.Suspense>
  )
}
