import { Heading } from '@betaeducacao/react-components'
import { Helmet } from 'react-helmet-async'

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>404 Página não encontrada</title>
      </Helmet>

      <div className="flex items-center justify-center w-screen h-screen bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
        <div className="px-6 md:px-40 py-20 bg-white sm:rounded-lg shadow-xl">
          <div className="flex flex-col items-center">
            <Heading size="9xl" className="text-blue-600 mb-4">
              404
            </Heading>

            <Heading
              as="h6"
              size="2xl"
              className="md:text-3xl text-gray-80 mb-2"
            >
              Página não encontrada
            </Heading>

            <p className="text-gray-500 md:text-lg">
              A página que você está procurando não existe.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
