import { Heading } from '@betaeducacao/react-components'

function H6({ children }) {
  return (
    <Heading as="h6" className="mb-2">
      {children}
    </Heading>
  )
}

export default function Faq() {
  return (
    <div className="grid md:grid-cols-2 gap-4 mt-4">
      <div>
        <H6>Posso solicitar devolução do meu dinheiro?</H6>
        <div>
          Sim. Você pode solicitar o reembolso do valor total da sua compra em até 7 dias após a liberação.{' '}
          <u>Lembrando que o curso não pode estar concluído para o reembolso.</u>
        </div>
      </div>
      <div>
        <H6>Demora para identificar o pagamento?</H6>
        <div>
          Se optou por boleto bancário, <u>poderá demorar até 48 horas</u>. Se utilizou o <u>cartão de crédito</u> ou{' '}
          <u>pix</u>, dependerá apenas da operadora confirmar seu pagamento.{' '}
          <u>Após a liberação você receberá um email.</u>
        </div>
      </div>
      <div>
        <H6>O que acontece se o boleto bancário vencer?</H6>
        <div>
          Caso você não consiga efetuar o pagamento no prazo de vencimento do boleto, não se preocupe.{' '}
          <u>O boleto expirará automaticamente após o vencimento e não será gerado protesto.</u>
        </div>
      </div>
      <div>
        <H6>Pagamento no cartão de crédito é seguro?</H6>
        <div>
          Sim. Você esta utilizando uma conexão segura e não salvamos quaisquer dados de seu cartão de créditos.{' '}
          <u>Todos os dados para pagamento será passado direto para a operadora.</u>
        </div>
      </div>
    </div>
  )
}
