import { Button, Input, Spinner, useAxios } from '@betaeducacao/react-components'
import { isValidCEP } from '@brazilian-utils/brazilian-utils'
import { ArrowTopRightOnSquareIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import * as yup from 'yup'
import { Error, Label } from '..'
import { onlyNumbers } from '../../utils'

const schema = yup.object({
  postcode: yup
    .string()
    .required('Você deve preencher o CEP')
    .test('postcode', 'Deve ser um CEP válido', isValidCEP)
    .transform(onlyNumbers),
})

export default function Postcode({ onSubmit, ...props }) {
  const axios = useAxios()
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange', resolver: yupResolver(schema) })
  const { isLoading, mutateAsync } = useMutation(async (cep) => {
    const { data } = await axios.get(`/look-up/${cep}/address`)
    return data
  })

  const _onSubmit = async ({ postcode }) => {
    const data = await mutateAsync(postcode)
    onSubmit(data)
  }

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Label aria-required={true} className="w-full md:w-1/2">
        <span>CEP</span>
        <div className="flex gap-1">
          <Controller
            control={control}
            name="postcode"
            render={({ field: { ref, ...props } }) => {
              return (
                <Input
                  as={PatternFormat}
                  format="#####-###"
                  mask="_"
                  placeholder="_____-___"
                  size="lg"
                  className="w-full md:w-52"
                  autoFocus={true}
                  getInputRef={ref}
                  {...props}
                />
              )
            }}
            {...props}
          />

          <Button type="submit" size="lg" className="w-14 justify-center" tabIndex={-1}>
            {isLoading ? <Spinner /> : <MagnifyingGlassIcon className="w-5" />}
          </Button>
        </div>

        <Error name="postcode" errors={formState.errors} />

        <a
          href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1 text-sm text-indigo-500 underline hover:no-underline"
        >
          Não sei meu CEP <ArrowTopRightOnSquareIcon className="w-4" />
        </a>
      </Label>
    </form>
  )
}
