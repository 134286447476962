import { Badge } from '@betaeducacao/react-components'
import classNames from 'classnames'

export const CREATING = 'CREATING'
export const PENDING = 'PENDING'
export const PAID = 'PAID'
export const CANCELED = 'CANCELED'
export const EXPIRED = 'EXPIRED'
export const REFUNDED = 'REFUNDED'
export const DECLINED = 'DECLINED'

const colors = {
  [CREATING]: 'secondary',
  [PENDING]: 'secondary',
  [PAID]: 'success',
  [CANCELED]: 'danger',
  [EXPIRED]: 'warning',
  [REFUNDED]: 'warning',
  [DECLINED]: 'danger',
}

const statuses = {
  [CREATING]: 'Pendente',
  [PENDING]: 'Pendente',
  [PAID]: 'Pago',
  [CANCELED]: 'Cancelado',
  [EXPIRED]: 'Expirado',
  [REFUNDED]: 'Estornado',
  [DECLINED]: 'Negado',
}

export default function Status({ children, className }) {
  return (
    <Badge colorScheme={colors[children]} rounded="lg" className={classNames('lowercase', className)}>
      {statuses[children]}
    </Badge>
  )
}
