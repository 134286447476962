import { currency, Heading, Spinner } from '@betaeducacao/react-components'
import { always, cond, equals, T } from 'ramda'
import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import LazyLoad from './lazy-load'
import { CREATING, PENDING } from './status'
import { PaymentContext } from './thanks'

export const MANUAL = 'MANUAL'
export const PIX = 'PIX'
export const BANK_SLIP = 'BANK_SLIP'
export const CREDIT_CARD = 'CREDIT_CARD'

export const paymentMethods = {
  [MANUAL]: 'Depósito bancário',
  [PIX]: 'Pix',
  [BANK_SLIP]: 'Boleto bancário',
  [CREDIT_CARD]: 'Cartão de crédito',
}

const componentsMethod = cond([
  [equals(PIX), always(Pix)],
  [equals(BANK_SLIP), always(BankSlip)],
  [equals(CREDIT_CARD), always(CreditCard)],
  [T, always(Manual)],
])

function Manual() {
  return (
    <>
      <p>{paymentMethods[MANUAL]}</p>
      <p className="md:w-56">
        Banco Itaú, agência <strong className="underline">1575</strong>, conta corrente{' '}
        <strong className="underline">18505-1</strong>
      </p>
    </>
  )
}

function Pix() {
  return (
    <PaymentContext.Consumer>
      {({ payment: { pix, status, due_date } }) => (
        <div className="space-y-1">
          <p>{paymentMethods[PIX]}</p>

          {[CREATING, PENDING].includes(status) && (
            <Countdown
              date={due_date}
              renderer={({ minutes, seconds, completed }) => {
                if (completed) {
                  return <></>
                }

                return (
                  <>
                    <div className="font-bold text-3xl">
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </div>

                    <LazyLoad
                      placeholder={
                        <div className="relative w-48 h-48">
                          <div className="w-full h-full rounded bg-gray-200" />
                          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <Spinner colorScheme="light" />
                          </span>
                        </div>
                      }
                    >
                      <img src={pix?.qrcode} className="w-48 h-48 border rounded" alt={pix?.qrcode_text} />
                    </LazyLoad>

                    {pix?.qrcode_text && (
                      <div className="text-xs border p-1 rounded bg-gray-50 w-64">
                        <p className="text-red-900 break-all select-all font-mono">{pix.qrcode_text}</p>
                      </div>
                    )}

                    <div className="w-64">
                      <Heading as="h6">Como pagar com Pix:</Heading>
                      <ol className="list-decimal list-inside text-sm">
                        <li>Acesse o app ou site do seu banco</li>
                        <li>Busque a opção de pagar com Pix</li>
                        <li>Leia o QR code ou código Pix</li>
                        <li>Pronto! Você verá a confirmação do pagamento</li>
                      </ol>
                    </div>
                  </>
                )
              }}
            />
          )}
        </div>
      )}
    </PaymentContext.Consumer>
  )
}

function BankSlip() {
  return (
    <PaymentContext.Consumer>
      {({ payment: { invoice } }) => (
        <div className="space-y-1">
          <p>{paymentMethods[BANK_SLIP]}</p>
          {invoice?.pdf ? (
            <p>
              <a
                href={invoice?.pdf}
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 underline hover:no-underline"
              >
                Abrir o boleto bancário
              </a>
            </p>
          ) : (
            <Spinner colorScheme="light" size="sm" />
          )}
        </div>
      )}
    </PaymentContext.Consumer>
  )
}

function CreditCard() {
  return (
    <PaymentContext.Consumer>
      {({ payment: { installments, total, credit_card } }) => (
        <div>
          <p>
            {installments}x {currency(total / installments)}
          </p>

          <p>
            {credit_card.brand === 'other' ? (
              <>
                {paymentMethods[CREDIT_CARD]} **** {credit_card.last4}
              </>
            ) : (
              <>
                {credit_card.brand} (Crédito) **** {credit_card.last4}
              </>
            )}
          </p>
        </div>
      )}
    </PaymentContext.Consumer>
  )
}

export default function PaymentMethod({ children }) {
  const Component = componentsMethod(children)
  return <Component />
}
