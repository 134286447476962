import { Container, Logo } from '@betaeducacao/react-components'
import { Helmet } from 'react-helmet-async'
import Copyright from './copyright'

const appName = 'Beta Educação'

export default function Layout({ children }) {
  return (
    <>
      <Helmet titleTemplate={`%s - ${appName}`} />

      <Container className="space-y-4 md:space-y-8 px-4 my-4 md:mt-8">
        <div className="flex justify-center">
          <Logo className="rounded-lg w-24 h-24" />
        </div>

        {children}

        <Copyright appName={appName} />
      </Container>
    </>
  )
}
