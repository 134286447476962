import {
  Button,
  Card,
  Currency,
  HStack as HStack_,
  kindIsItemNotDiscount,
  Skeleton,
  useDisclosure,
} from '@betaeducacao/react-components'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import React from 'react'
import useCheckout from './use-checkout'

const UpdateCartItems = React.lazy(() => import('./update-cart-items'))

function HStack({ children }) {
  // eslint-disable-next-line
  return <HStack_ className="justify-between gap-2">{children}</HStack_>
}

const Item = ({ name, quantity, unit_price }) => {
  return (
    <li className="space-y-1">
      <HStack>
        <div>{name}</div>
        <Currency className="text-gray-400">{unit_price * quantity}</Currency>
      </HStack>

      <div className="text-gray-400 text-sm">
        <Currency>{unit_price}</Currency> x {quantity} unit.
      </div>
    </li>
  )
}

const Cart = React.memo(() => {
  const { items, total, subtotal, discount, interest } = useCheckout()
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Card as="ul" className="overflow-hidden [&>li]:p-4 divide-y">
        {items.filter(kindIsItemNotDiscount).map((props) => (
          <Item key={props.id} {...props} />
        ))}

        <li className="bg-gray-50 space-y-2">
          {interest || discount ? (
            <HStack>
              <span>Subtotal</span>
              <Currency className="text-gray-400">{subtotal}</Currency>
            </HStack>
          ) : null}

          {discount ? (
            <HStack>
              <div>Desconto</div>
              <Currency className="text-gray-400">{discount}</Currency>
            </HStack>
          ) : null}

          {interest ? (
            <HStack>
              <div>Juros</div>
              <Currency className="text-gray-400">{interest}</Currency>
            </HStack>
          ) : null}

          <HStack>
            <span>Total</span>
            <Currency as="strong">{total}</Currency>
          </HStack>
        </li>
      </Card>

      <div className="flex justify-center">
        <React.Suspense fallback={<Skeleton className="w-28 h-6 rounded-md" />}>
          <UpdateCartItems isOpen={isOpen} onClose={onClose} />

          <Button variant="link" className="inline-flex gap-1 items-center" onClick={onOpen}>
            <ShoppingCartIcon className="w-5 h-5" /> Alterar itens
          </Button>
        </React.Suspense>
      </div>
    </>
  )
})

export default Cart
