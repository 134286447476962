import classNames from 'classnames'
import React, { useState } from 'react'

export default function LazyLoad({ children, afterLoad, placeholder }) {
  const [loading, setLoad] = useState(true)

  const onLoad = () => {
    setLoad(false)
    afterLoad()
  }

  return (
    <>
      {loading ? placeholder : null}
      {React.cloneElement(children, {
        className: classNames(children.props.className, { hidden: loading }),
        onLoad,
      })}
    </>
  )
}

LazyLoad.defaultProps = {
  afterLoad: () => {},
}
