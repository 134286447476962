/**
 * https://developers.google.com/tag-platform/gtagjs/reference/events
 */

import ReactGA from 'react-ga4'

function fmtItem({ id, name, unit_price, quantity }) {
  return {
    item_id: id,
    item_name: name,
    price: unit_price,
    quantity,
  }
}

export function gaInit(gaMeasurementId) {
  ReactGA.initialize(gaMeasurementId, { testMode: process.env.NODE_ENV !== 'production' })
}

export function beginCheckout({ total, items }) {
  ReactGA.event('begin_checkout', {
    currency: 'BRL',
    value: total,
    items: items.map(fmtItem),
  })
}

export function purchase({ id, total, items }) {
  ReactGA.event('purchase', {
    currency: 'BRL',
    transaction_id: id,
    value: total,
    items: items.map(fmtItem),
  })
}
