import {
  Button,
  Card,
  FormControl,
  Heading,
  Input,
  Spacing,
  Spinner,
  useAxios,
  useChallenge,
} from '@betaeducacao/react-components'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Error } from '../../components'
import { beginCheckout } from '../../libs/ga4'
import useCheckout from './use-checkout'

const schema = yup.object({
  email: yup.string().trim().lowercase().email('Deve ser um email válido').required('Você deve preencher o email'),
})

export default function Id({ disabled }) {
  const axios = useAxios()
  const challenge = useChallenge()
  const { total, items } = useCheckout()
  const { mutateAsync } = useMutation(async (email) => {
    const { data } = await axios.get(`/look-up/${email}`)
    return data
  })
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ email }) => {
    try {
      const { name } = await mutateAsync(email)
      challenge('buyer', { email, assignee: { name }, name: undefined, userAlreadyExists: true })
      beginCheckout({ total, items })
    } catch {
      challenge('buyer', { email, name: undefined, userAlreadyExists: false })
    }
  }

  return (
    <>
      <Helmet>
        <title>Para finalizar a compra, informe seu email</title>
      </Helmet>

      <Spacing>
        <Heading size="3xl">Para finalizar a compra, informe seu email</Heading>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-1">
          <FormControl aria-invalid={'email' in formState.errors} disabled={disabled}>
            <fieldset
              className="flex items-center bg-white border rounded-lg focus-within:ring focus-within:ring-indigo-300 focus-within:border-indigo-500 aria-invalid:border-red-500 aria-invalid:ring-red-300 [&>*:focus]:ring-0 [&>*:focus]:ring-offset-0"
              aria-invalid={'email' in formState.errors}
            >
              <Input
                placeholder="seu@email.com"
                rounded="md"
                size="xl"
                autoFocus={true}
                className="md:text-center w-full border-0"
                {...register('email')}
              />

              <Button
                type="submit"
                className="w-36 rounded-l-none flex items-center justify-center"
                disabled={formState.isSubmitting}
                size="xl"
              >
                {formState.isSubmitting ? <Spinner size="md" /> : <>Continuar</>}
              </Button>
            </fieldset>

            <Error name="email" errors={formState.errors} />
          </FormControl>
        </form>

        <Card>
          <Card.Body as={Spacing} className="md:py-6 md:px-8">
            <Heading as="h5" className="flex gap-1">
              <div className="text-green-600">
                <LockClosedIcon className="w-5 h-5" />
              </div>
              Usamos seu email de forma 100% segura para:
            </Heading>
            <ul className="list-disc list-inside ml-4 text-sm">
              <li>Identificar seu perfil</li>
              <li>Notificar sobre o andamento do seu pedido</li>
              <li>Gerenciar seu histórico de compras</li>
              <li>Acelerar o preenchimento de suas informações</li>
              <li>Criar uma conta para acesso aos cursos se não houver</li>
            </ul>
          </Card.Body>
        </Card>
      </Spacing>
    </>
  )
}
