import { AxiosProvider } from '@betaeducacao/react-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet, Route, Routes } from 'react-router-dom'
import Layout from './components/layout'
import './index.css'
import { gaInit } from './libs/ga4'
import NotFound from './pages/404'
import Checkout from './pages/checkout'
import Thanks from './pages/thanks'

gaInit(process.env.REACT_APP_GA_MEASUREMENT_ID)

const axiosConfig = { baseURL: process.env.REACT_APP_API_URL }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

function ErrorFallback({ error: { response } }) {
  if (response.status === 404) {
    return <NotFound />
  }

  return <>Internal Server Error</>
}

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AxiosProvider config={axiosConfig}>
        <Routes>
          <Route
            element={
              <QueryClientProvider client={queryClient}>
                <Layout>
                  <Outlet />
                </Layout>
              </QueryClientProvider>
            }
          >
            <Route path=":id" element={<Checkout />} />
            <Route path="/thanks/:id" element={<Thanks />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AxiosProvider>
    </ErrorBoundary>
  )
}

export default App
