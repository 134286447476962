import {
  Button,
  Card,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputGroupText,
  Radio,
  Spacing,
  VStack,
  useChallenge,
} from '@betaeducacao/react-components'
import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { omit } from 'ramda'
import React, { useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Controller, get, useForm } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import * as yup from 'yup'
import { Divider, Error } from '../../components'
import { isCNPJ, isName, onlyNumbers } from '../../utils'
import Layout from './layout'
import useCheckout from './use-checkout'

const schema = yup.object({
  doctype: yup.mixed().oneOf(['cpf', 'cnpj']).required(),
  number: yup.string().when('doctype', {
    is: isCNPJ,
    then: () =>
      yup
        .string()
        .required('Você deve preencher o CNPJ')
        .test('cnpj', 'Deve ser um CNPJ válido', isValidCNPJ)
        .transform(onlyNumbers),
    otherwise: () =>
      yup
        .string()
        .required('Você deve preencher o CPF')
        .test('cnpj', 'Deve ser um CPF válido', isValidCPF)
        .transform(onlyNumbers),
  }),
  name: yup
    .string()
    .trim()
    .when('doctype', {
      is: isCNPJ,
      then: () => yup.string().required('Você deve preencher a empresa'),
      otherwise: () =>
        yup.string().required('Você deve preencher o nome').test('name', 'Deve ser um nome completo', isName),
    }),
  assignee: yup.object().when('doctype', {
    is: isCNPJ,
    then: () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .required('Você deve preencher o nome do gestor')
          .test('name', 'Deve ser um nome completo', isName),
      }),
  }),
  phone_number: yup
    .string()
    .transform(onlyNumbers)
    .transform((x) => (x ? `+55${x}` : x)),
})

export default function Buyer(challengeData) {
  const doctype = 'cpf' in challengeData ? 'cpf' : 'cnpj'
  const challenge = useChallenge()
  const { forcesCNPJ } = useCheckout()
  const { register, handleSubmit, watch, setFocus, setValue, formState, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      doctype,
      number: challengeData[doctype],
      phone_number: challengeData?.phone_number?.replace('+55', '') ?? undefined,
      ...omit(['cpf', 'cnpj', 'phoneNumber'], challengeData),
    },
  })

  const onSubmit = ({ doctype, number, ...buyer }) => {
    const data = doctype === 'cnpj' ? buyer : omit(['assignee', 'cnpj'], buyer)
    challenge('address', { [doctype]: number, ...data })
  }

  const handleChange = ({ target: { value } }) => {
    const name = challengeData?.assignee?.name ?? challengeData.name

    setFocus('number')
    setValue('number', '')
    setValue('name', value === 'cpf' ? name : '', { shouldValidate: true })
  }

  const hasError = useCallback(
    (errorName) => {
      return !!get(formState.errors, errorName)
    },
    [formState.errors]
  )

  const checkedCNPJ = watch('doctype') === 'cnpj'
  const checkedCPF = watch('doctype') === 'cpf'

  useEffect(() => {
    if (forcesCNPJ && checkedCPF) {
      setValue('doctype', 'cnpj', { shouldValidate: true })
      setValue('name', '', { shouldValidate: true })
    }
    // eslint-disable-next-line
  }, [forcesCNPJ, checkedCPF])

  return (
    <>
      <Helmet>
        <title>Informações do comprador</title>
      </Helmet>

      <Layout>
        <Spacing as="form" onSubmit={handleSubmit(onSubmit)}>
          <Heading size="2xl">Informações do comprador</Heading>
          <Card>
            <Card.Body as={Spacing} className="md:p-8">
              <FormControl aria-invalid={hasError('number')}>
                <InputGroup rounded="md">
                  <InputGroupText as={HStack} gap={3}>
                    <HStack gap={1.5}>
                      <Radio
                        value="cpf"
                        disabled={forcesCNPJ}
                        id="cpf"
                        className="p-2"
                        aria-invalid={false}
                        {...register('doctype', { onChange: handleChange })}
                      />
                      <FormLabel htmlFor="cpf">CPF</FormLabel>
                    </HStack>
                    <HStack gap={1.5}>
                      <Radio
                        value="cnpj"
                        id="cnpj"
                        className="p-2"
                        aria-invalid={false}
                        {...register('doctype', { onChange: handleChange })}
                      />
                      <FormLabel htmlFor="cnpj">CNPJ</FormLabel>
                    </HStack>
                  </InputGroupText>

                  <Controller
                    control={control}
                    name="number"
                    render={({ field: { ref, ...props } }) => {
                      return (
                        <Input
                          as={PatternFormat}
                          format={checkedCNPJ ? '##.###.###/####-##' : '###.###.###-##'}
                          placeholder={checkedCNPJ ? '__.___.___/____-__' : '___.___.___-__'}
                          mask="_"
                          size="lg"
                          className="w-full"
                          autoFocus={true}
                          getInputRef={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                </InputGroup>
                <Error name="number" errors={formState.errors} />
              </FormControl>

              <HStack gap={4} className="flex-col md:flex-row [&>label]:w-full" align="start">
                <FormControl as={VStack} gap={0.5} aria-required={true} aria-invalid={hasError('name')}>
                  <FormLabel>{checkedCNPJ ? 'Empresa' : 'Nome completo'}</FormLabel>
                  <Input
                    size="lg"
                    className="w-full"
                    readOnly={checkedCPF && challengeData.userAlreadyExists}
                    tabIndex={checkedCPF && challengeData.userAlreadyExists ? -1 : 0}
                    {...register('name')}
                  />
                  <Error name="name" errors={formState.errors} />
                </FormControl>

                <FormControl as={VStack} gap={0.5}>
                  <FormLabel>Telefone</FormLabel>
                  <Controller
                    control={control}
                    name="phone_number"
                    render={({ field: { ref, ...props } }) => {
                      return (
                        <Input
                          as={PatternFormat}
                          format="(##) ####-#####"
                          mask="*"
                          placeholder="(**) ****-****"
                          size="lg"
                          className="w-full"
                          // getInputRef={ref}
                          {...props}
                        />
                      )
                    }}
                  />
                  <Error name="phone_number" errors={formState.errors} />
                </FormControl>
              </HStack>

              {checkedCNPJ && (
                <FormControl as={VStack} gap={0.5} aria-required={true} aria-invalid={hasError('assignee.name')}>
                  <FormLabel>Nome do gestor</FormLabel>
                  <Input
                    size="lg"
                    className="w-full"
                    defaultValue={challengeData.name}
                    readOnly={challengeData.userAlreadyExists}
                    tabIndex={challengeData.userAlreadyExists ? -1 : 0}
                    {...register('assignee.name')}
                  />
                  <Error name="assignee.name" errors={formState.errors} />
                </FormControl>
              )}

              <FormControl as={VStack} gap={0.5} aria-required={true}>
                <FormLabel>Email</FormLabel>
                <Input size="lg" className="w-full" readOnly={true} tabIndex={-1} {...register('email')} />
                <Error name="email" errors={formState.errors} />
              </FormControl>
            </Card.Body>
          </Card>

          <Divider />

          <Button type="submit" size="lg" className="w-full">
            Continuar
          </Button>
        </Spacing>
      </Layout>
    </>
  )
}
