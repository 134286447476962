import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputGroupText,
  Spacing,
  VStack,
  useChallenge,
  useDisclosure,
} from '@betaeducacao/react-components'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { get, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Divider, Error } from '..'
import Layout from './layout'
import Postcode from './postcode'

const schema = yup.object({
  postcode: yup.string().trim().required(),
  street: yup.string().trim().required('Você deve preencher o endereço'),
  street_number: yup
    .string()
    .trim()
    .required('Você deve preencher o número')
    .test('number', 'Deve ser um número válido', (x) => (x === 'S/N' ? true : Number(x) > 0)),
  complement: yup.string().trim().nullable(),
  neighborhood: yup.string().required('Você deve preencher o bairro'),
  city: yup.string().required('Você deve preencher o cidade'),
  state: yup.string().trim().required('Você deve preencher o estado'),
})

export default function Address(challengeData) {
  const challenge = useChallenge()
  const [autofill, setAutofill] = useState(true)
  const { isOpen, setIsOpen } = useDisclosure({ defaultIsOpen: challengeData?.address?.street_number === 'S/N' })
  const { register, handleSubmit, setFocus, setValue, reset, watch, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: challengeData?.address,
  })

  const handleFetchPostcode = (data) => {
    reset({ street_number: '', complement: '', ...data })
    setAutofill(data?.street !== '')
    setIsOpen(false)
  }

  const handleClick = () => {
    setIsOpen((prevIsOpen) => {
      const isOpen = !prevIsOpen
      const value = isOpen ? 'S/N' : ''
      setValue('street_number', value, { shouldValidate: true })
      setFocus('street_number')
      return isOpen
    })
  }

  const onSubmit = (address) => challenge('payment', { ...challengeData, address })

  const hasError = useCallback((name) => !!get(formState.errors, name), [formState.errors])

  const address = watch()

  return (
    <>
      <Helmet>
        <title>Endereço de cobrança</title>
      </Helmet>

      <Layout>
        <Heading size="2xl" className="space-x-2">
          <span>Endereço de cobrança</span>
          <span className="text-sm text-gray-500 font-light">Será utilizado para emissão da NFS-e</span>
        </Heading>

        <Card>
          <Card.Body as={Spacing} className="md:p-8">
            <Postcode defaultValue={address?.postcode} onSubmit={handleFetchPostcode} />

            <form onSubmit={handleSubmit(onSubmit)} id="address">
              <Spacing as="fieldset" disabled={!address.hasOwnProperty('postcode')}>
                <div className="flex flex-col md:flex-row gap-4" align="start">
                  <FormControl
                    as={VStack}
                    gap={0.5}
                    className="md:w-4/6"
                    aria-required={true}
                    aria-invalid={hasError('street')}
                  >
                    <FormLabel>Endereço</FormLabel>
                    <Input size="lg" tabIndex={address?.street ? -1 : 0} readOnly={autofill} {...register('street')} />
                    <Error name="street" errors={formState.errors} />
                  </FormControl>

                  <FormControl
                    as={VStack}
                    gap={0.5}
                    className="md:w-2/6"
                    aria-required={true}
                    aria-invalid={hasError('street_number')}
                  >
                    <FormLabel>Número</FormLabel>
                    <InputGroup rounded="md" className="[&>input:read-only]:transition-none">
                      <Input
                        size="lg"
                        className={classNames({
                          'text-transparent select-none': isOpen,
                        })}
                        id="number"
                        readOnly={isOpen}
                        tabIndex={isOpen ? -1 : 0}
                        {...register('street_number')}
                      />
                      <InputGroupText as={HStack} gap={2}>
                        <Checkbox onChange={handleClick} tabIndex={-1} checked={isOpen} id="sn" />
                        <label htmlFor="sn">S/N</label>
                      </InputGroupText>
                    </InputGroup>
                    <Error name="street_number" errors={formState.errors} />
                  </FormControl>
                </div>

                <FormControl as={VStack} gap={0.5}>
                  <FormLabel>Complemento</FormLabel>
                  <Input size="lg" {...register('complement')} />
                </FormControl>

                <div className="flex flex-col md:flex-row gap-4">
                  <FormControl aria-required={true} className="md:w-6/12" as={VStack} gap={0.5}>
                    <FormLabel>Bairro</FormLabel>
                    <Input
                      size="lg"
                      tabIndex={address?.neighborhood ? -1 : 0}
                      readOnly={autofill}
                      aria-invalid={hasError('neighborhood')}
                      {...register('neighborhood')}
                    />
                    <Error name="neighborhood" errors={formState.errors} />
                  </FormControl>

                  <FormControl aria-required={true} className="md:w-4/12" as={VStack} gap={0.5}>
                    <FormLabel>Cidade</FormLabel>
                    <Input size="lg" readOnly={true} tabIndex={-1} {...register('city')} />
                  </FormControl>

                  <FormControl aria-required={true} className="md:w-2/12" as={VStack} gap={0.5}>
                    <FormLabel>Estado</FormLabel>
                    <Input size="lg" readOnly={true} tabIndex={-1} {...register('state')} />
                  </FormControl>
                </div>
              </Spacing>
            </form>
          </Card.Body>
        </Card>

        <Divider />

        <Button type="submit" size="lg" className="w-full" form="address">
          Continuar
        </Button>

        <div className="text-center">
          <Button variant="link" onClick={() => challenge('buyer', challengeData)}>
            Voltar para informações do comprador
          </Button>
        </div>
      </Layout>
    </>
  )
}
