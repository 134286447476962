import { calcInterest, Card, currency, HStack, Input, Spacing } from '@betaeducacao/react-components'
import { fns as paymentFns } from 'payment'
import { range } from 'ramda'
import React, { useCallback } from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { Error, Label } from '../../components'
import { onlyNumbers } from '../../utils'
import { CardErrorIcon, creditCardsIcons } from './card-icons'
import useCheckout from './use-checkout'

const installments = (total, max) => {
  return range(1, max).map((idx) => {
    const installment = calcInterest(total, idx) / idx
    return idx === 1
      ? { value: idx, text: `${currency(total)} à vista` }
      : { value: idx, text: `${idx}x ${currency(installment)} com juros` }
  })
}

const renderCreditCards = (cardType) => {
  return Object.keys(creditCardsIcons).filter((x) => (cardType ? x === cardType : true))
}

export default function CreditCard() {
  const { subtotal, discount, setInterest } = useCheckout()
  const { register, watch, control, formState } = useFormContext()
  const cardNumber = watch('credit_card.number')
  const cardType = paymentFns.cardType(cardNumber)

  const handleChange = ({ target: { value } }) => {
    setInterest(value)
  }

  const hasError = useCallback((name) => !!get(formState.errors, name), [formState.errors])

  return (
    <>
      <Card>
        <Card.Body as={Spacing} className="md:p-8">
          <Label aria-required={true}>
            <span>Número do cartão</span>
            <div className="relative">
              <Controller
                name="credit_card.number"
                shouldUnregister={true}
                render={({ field: { ref, ...props }, fieldState }) => {
                  return (
                    <>
                      <Input
                        as={PatternFormat}
                        size="lg"
                        placeholder="**** **** **** ****"
                        format="#### #### #### ####"
                        mask="*"
                        className="w-full"
                        autoFocus={true}
                        aria-invalid={!!fieldState.error}
                        getInputRef={ref}
                        {...props}
                      />
                      <div className="absolute flex items-center gap-1 inset-y-0 right-4">
                        {fieldState.error ? (
                          <>
                            <CardErrorIcon className="h-8 w-auto fill-red-500" />
                          </>
                        ) : (
                          renderCreditCards(cardType)
                            .slice(0, 4)
                            .map((x, idx) =>
                              React.createElement(creditCardsIcons[x], {
                                className: 'h-5 w-auto',
                                key: idx,
                              })
                            )
                        )}
                      </div>
                    </>
                  )
                }}
              />
            </div>
            <Error name="credit_card.number" errors={formState.errors} />
          </Label>

          <Label aria-required={true}>
            <span>Nome do titular</span>
            <Input
              size="lg"
              className="w-full"
              aria-invalid={hasError('credit_card.name')}
              {...register('credit_card.name', { shouldUnregister: true })}
            />
            <Error name="credit_card.name" errors={formState.errors} />
          </Label>

          <HStack className="gap-4 flex-col md:flex-row" align="start">
            <HStack className="gap-4 w-full md:w-1/2" align="start">
              <Label aria-required={true} className="w-full">
                <span>Validade</span>
                <Controller
                  name="credit_card.exp"
                  control={control}
                  render={({ field: { ref, ...props }, fieldState }) => {
                    return (
                      <Input
                        as={PatternFormat}
                        size="lg"
                        className="w-full"
                        placeholder="mm / aa"
                        format="## / ##"
                        aria-invalid={!!fieldState.error}
                        getInputRef={ref}
                        {...props}
                      />
                    )
                  }}
                />
                <Error name="credit_card.exp" errors={formState.errors} />
              </Label>

              <Label aria-required={true} className="w-full">
                <span>CVC</span>
                <Input
                  size="lg"
                  className="w-full"
                  aria-invalid={hasError('credit_card.cvv')}
                  {...register('credit_card.cvv', {
                    setValueAs: onlyNumbers,
                    shouldUnregister: true,
                  })}
                />
                <Error name="credit_card.cvv" errors={formState.errors} />
              </Label>
            </HStack>

            <Label aria-required={true} className="w-full md:w-1/2">
              <span>Parcelas</span>
              <select
                size="lg"
                className="form-select rounded-md text-lg border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-300 h-12 py-0 w-full"
                {...register('installments', {
                  shouldUnregister: true,
                  onChange: handleChange,
                })}
              >
                {installments(subtotal + discount, 13).map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </Label>
          </HStack>
        </Card.Body>
      </Card>
    </>
  )
}
