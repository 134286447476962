import {
  Alert,
  Button,
  Heading,
  kindIsItem,
  Spacing,
  Spinner,
  useAxios,
  useChallenge,
} from '@betaeducacao/react-components'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { fns as paymentFns } from 'payment'
import { Helmet } from 'react-helmet-async'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Divider, Error } from '../../components'
import { purchase } from '../../libs/ga4'
import { isCreditCard, isName, onlyNumbers } from '../../utils'
import { CREDIT_CARD, MANUAL, paymentMethods, PIX } from '../payment-methods'
import CreditCard from './credit-card'
import Layout from './layout'
import useCheckout from './use-checkout'

const schema = yup.object({
  payment_method: yup.mixed().oneOf(Object.keys(paymentMethods)).required('Você deve escolher uma forma de pagamento'),
  credit_card: yup.object().when('payment_method', {
    is: isCreditCard,
    then: () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .required('Você deve preencher o nome do titular')
          .test('name', 'Deve ser um nome completo', isName),
        number: yup
          .string()
          .required('Você deve preencher o número do cartão')
          .test('number', 'Deve ser um cartão válido', paymentFns.validateCardNumber)
          .transform(onlyNumbers),
        cvv: yup.string().min(3, 'Deve ser um CVC válido').required('Você deve preencher o CVC'),
        exp: yup
          .string()
          .required('Você deve preencher a validade')
          .test('exp', 'Deve ser uma data válida', paymentFns.validateCardExpiry)
          .transform((s) => s.replace(/\s/g, '')),
      }),
  }),
  installments: yup.number().min(1),
})

export default function Payment(challengeData) {
  const axios = useAxios()
  const challenge = useChallenge()
  const navigate = useNavigate()
  const { mutateAsync } = useMutation(async (payload) => {
    const { data } = await axios.post('/checkout/', payload)
    return data
  })
  const formMethods = useForm({ resolver: yupResolver(schema) })
  const { register, handleSubmit, watch, formState } = formMethods
  const { allowManualPayment, dueDate, items, resetInterest } = useCheckout()

  const paymentMethod = watch('payment_method')
  const hasCNPJ = 'cnpj' in challengeData
  const isManualPayment = paymentMethod === MANUAL
  const isCreditCard = paymentMethod === CREDIT_CARD

  const onSubmit = async (payment) => {
    try {
      const order = await mutateAsync({
        items: items.filter(kindIsItem),
        due_date: dueDate,
        ...challengeData,
        ...payment,
      })

      purchase(order)
      navigate(`/thanks/${order.id}`, { state: order })
    } catch {
      // @todo show an error message
    }
  }

  const handleChange = () => {
    resetInterest()
  }

  const denyPaymetMethods = ([idx, _]) => {
    if (hasCNPJ && idx === PIX) {
      return false
    }

    if (allowManualPayment === false && idx === MANUAL) {
      return false
    }

    return true
  }

  return (
    <>
      <Helmet>
        <title>Forma de pagamento</title>
      </Helmet>

      <Layout>
        <Heading size="2xl">Forma de pagamento</Heading>
        <FormProvider {...formMethods}>
          <Spacing as="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <ul className="grid justify-items-stretch md:grid-cols-3 gap-2">
                {Object.entries(paymentMethods)
                  .filter(denyPaymetMethods)
                  .map(([idx, text]) => (
                    <li key={idx} className="relative">
                      {paymentMethod === idx && (
                        <CheckCircleIcon className="w-6 h-6 top-2.5 right-2.5 absolute text-indigo-500" />
                      )}

                      <input
                        type="radio"
                        value={idx}
                        id={idx}
                        className="sr-only peer"
                        {...register('payment_method', {
                          onChange: handleChange,
                        })}
                      />

                      <label
                        className="flex items-center p-6 h-full font-semibold bg-white border border-gray-200 rounded-lg cursor-pointer focus:outline-none peer-checked:ring-1 peer-checked:ring-indigo-500 peer-checked:border-indigo-500"
                        htmlFor={idx}
                      >
                        {text}
                      </label>
                    </li>
                  ))}
              </ul>

              <Error name="paymentMethod" errors={formState.errors} />
            </div>

            {isManualPayment && (
              <Alert colorScheme="warning" className="items-start">
                <div className="space-y-2">
                  <p>
                    Banco Itaú, agência <strong className="underline">1575</strong>, conta corrente{' '}
                    <strong className="underline">18505-1</strong>
                  </p>
                  <p>Após efetuar o depósito ou transferência bancária entre em contato conosco.</p>
                </div>
              </Alert>
            )}

            {isCreditCard && <CreditCard />}

            <Divider />

            <Button
              type="submit"
              size="lg"
              className="w-full flex items-center justify-center"
              disabled={formState.isSubmitting}
            >
              {formState.isSubmitting ? <Spinner size="md" /> : <>Continuar</>}
            </Button>

            <div className="text-center">
              <Button variant="link" onClick={() => challenge('address', challengeData)}>
                Voltar para endereço de cobrança
              </Button>
            </div>
          </Spacing>
        </FormProvider>
      </Layout>
    </>
  )
}
