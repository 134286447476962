import { FormError } from '@betaeducacao/react-components'
import { ErrorMessage } from '@hookform/error-message'
import PropTypes from 'prop-types'

export default function Error({ name, errors }) {
  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => <FormError className="mt-1">{message}</FormError>}
    />
  )
}

Error.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
}
