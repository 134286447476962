import classNames from 'classnames'

export default function Label({ children, className, ...props }) {
  return (
    <label
      className={classNames(
        'space-y-1 block cursor-pointer [&>span]:block',
        "aria-required:[&>span:after]:content-['*']",
        'aria-required:[&>span:after]:ml-0.5 aria-required:[&>span:after]:text-red-500',
        className
      )}
      {...props}
    >
      {children}
    </label>
  )
}

Label.Inline = ({ children, className }) => {
  return (
    <label className={classNames('inline-flex gap-1.5 items-center [&>span]:cursor-pointer', className)}>
      {children}
    </label>
  )
}
