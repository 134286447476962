import formatStrByPattern from 'format-string-by-pattern'
import { CREDIT_CARD } from './components/payment-methods'

export function isCreditCard(x) {
  return x === CREDIT_CARD
}

export function isCNPJ(x) {
  return x === 'cnpj'
}

export function isName(x) {
  return x && x.includes(' ')
}

export function onlyNumbers(x) {
  return x ? x.replace(/\D/g, '') : x
}

export function formatInput(fn) {
  return (e) => {
    if (e.target.value) {
      e.target.value = fn(e.target.value)
    }
    return e
  }
}

export function formatPhone(phone) {
  return formatStrByPattern(phone.length === 14 ? '+xx (xx) x xxxx-xxxx' : '+xx (xx) xxxx-xxxx', phone)
}
