import {
  Abbr,
  Card,
  CpfCnpj,
  Currency,
  Datetime,
  Heading,
  HStack,
  kindIsDiscount,
  kindIsInterest,
  kindIsItemNotDiscount,
  Spacing,
  sumItems,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useAxios,
} from '@betaeducacao/react-components'
import { formatCEP } from '@brazilian-utils/brazilian-utils'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useWebSocket from 'react-use-websocket'
import { formatPhone } from '../utils'
import PaymentMethod from './payment-methods'
import Status from './status'

const socketUrl = process.env.REACT_APP_WEBSOCKET_URL
const dateOptions = {
  hour: '2-digit',
  minute: '2-digit',
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
}

export const PaymentContext = React.createContext(null)

function HoC(f) {
  return ({ children }) => f(children)
}

function Mask({ as, component, children, ...props }) {
  if (children) {
    return children.includes('*')
      ? React.createElement(as, props, children)
      : React.createElement(component, { as, ...props }, children)
  }

  return <></>
}

function Item({ name, quantity, unit_price }) {
  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{quantity}</Td>
      <Currency as={Td}>{unit_price}</Currency>
      <Currency as={Td}>{quantity * unit_price}</Currency>
    </Tr>
  )
}

export default function Thanks() {
  const { id } = useParams()
  const axios = useAxios()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { data } = useQuery(
    ['checkout', id],
    async () => {
      const { data } = await axios.get(`/checkout/${id}`)
      return data
    },
    {
      suspense: true,
      initialData: state ? state : undefined,
    }
  )
  const [payment, setPayment] = useState(state || data)

  useWebSocket(`${socketUrl}?id=${id}`, {
    shouldReconnect: () => true,
    onMessage: ({ data }) => {
      const props = { ...payment, ...JSON.parse(data) }
      setPayment(props)
      navigate('.', { state: props, replace: true })
    },
  })

  const subtotal = sumItems(payment.items.filter(kindIsItemNotDiscount))
  const discount = sumItems(payment.items.filter(kindIsDiscount))
  const interest = sumItems(payment.items.filter(kindIsInterest))
  const total = sumItems(payment.items)

  return (
    <>
      <Helmet>
        <title>{id}</title>
      </Helmet>

      <Card>
        <Card.Body as={Spacing} className="md:p-8">
          <HStack className="justify-between gap-4">
            <Heading size="2xl" className="md:text-3xl">
              <Abbr maxLen={10}>{id}</Abbr>
            </Heading>
            <Status>{payment.status}</Status>
          </HStack>

          <HStack align="start" className="flex-col md:flex-row justify-between gap-2 md:gap-4">
            <Spacing>
              <ul>
                <li>
                  <Heading as="h5">{payment.name}</Heading>
                </li>
                <Mask as="li" component={CpfCnpj}>
                  {payment.cpf}
                </Mask>
                <Mask as="li" component={CpfCnpj}>
                  {payment.cnpj}
                </Mask>
                <li>
                  {payment.address.street}, {payment.address.street_number}
                </li>
                <li>{payment.address.complement}</li>
                <li>{payment.address.neighborhood}</li>
                <li>
                  {payment.address.city}, {payment.address.state}
                </li>
                <Mask as="li" component={HoC(formatCEP)}>
                  {payment.address.postcode}
                </Mask>
              </ul>

              <ul>
                <li>{payment?.assignee?.name}</li>
                <li>{payment.email}</li>
                <Mask as="li" component={HoC(formatPhone)}>
                  {payment.phone_number}
                </Mask>
              </ul>
            </Spacing>

            <Spacing>
              <ul>
                <li>
                  <Heading as="h5">Forma de pagamento</Heading>
                </li>
                <li>
                  <PaymentContext.Provider value={{ payment }}>
                    <PaymentMethod>{payment.payment_method}</PaymentMethod>
                  </PaymentContext.Provider>
                </li>
              </ul>

              <ul>
                <li>
                  Compra feita em <Datetime options={dateOptions}>{payment.create_date}</Datetime>
                </li>
                <li>
                  Vencimento para <Datetime options={dateOptions}>{payment.due_date}</Datetime>
                </li>
                {payment.payment_date && (
                  <li>
                    Pago em <Datetime options={dateOptions}>{payment.payment_date}</Datetime>
                  </li>
                )}
              </ul>
            </Spacing>
          </HStack>

          <Table>
            <Thead>
              <Tr>
                <Th>Item</Th>
                <Th>Qtd.</Th>
                <Th>Valor unit.</Th>
                <Th>Total</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payment.items.filter(kindIsItemNotDiscount).map((props) => (
                <Item key={props.id} {...props} />
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Td colSpan={3} className="text-right">
                  Subtotal
                </Td>
                <Currency as={Td}>{subtotal}</Currency>
              </Tr>
              <Tr>
                <Td colSpan={3} className="text-right">
                  Desconto
                </Td>
                <Currency as={Td}>{discount}</Currency>
              </Tr>
              {interest ? (
                <Tr>
                  <Td colSpan={3} className="text-right">
                    Juros
                  </Td>
                  <Currency as={Td}>{interest}</Currency>
                </Tr>
              ) : null}
              <Tr>
                <Td colSpan={3} className="text-right">
                  Total
                </Td>
                <Currency as={Td}>{total}</Currency>
              </Tr>
            </Tfoot>
          </Table>

          <Spacing className="text-sm text-gray-500">
            <p>
              Todas os boletos devem ser pagos no prazo de 24 horas a partir da geração da compa. Se o boleto não for
              pago no prazo de 24 horas, será necessário efetuar uma nova compa.
            </p>
            <p>
              Todas as mudanças no status de pagamento da compra será enviada para seu email. Sempre verifique sua caixa
              de spam para evitar eventuais falhas de comunicação.
            </p>
          </Spacing>
        </Card.Body>
      </Card>
    </>
  )
}
